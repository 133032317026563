// extracted by mini-css-extract-plugin
export var lbContainerOuter = "D_vn";
export var lbContainerInner = "D_vp";
export var movingForwards = "D_vq";
export var movingForwardsOther = "D_vr";
export var movingBackwards = "D_vs";
export var movingBackwardsOther = "D_vt";
export var lbImage = "D_vv";
export var lbOtherImage = "D_vw";
export var prevButton = "D_vx";
export var nextButton = "D_vy";
export var closing = "D_vz";
export var appear = "D_vB";