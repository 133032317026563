// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "v_rW d_gS d_cw d_dv";
export var quoteParagraphCenter = "v_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "v_gV d_gV d_cw d_dx";
export var quoteRowLeft = "v_rX d_bG";
export var quoteRowCenter = "v_rY d_bD";
export var quoteRowRight = "v_rZ d_bH";
export var quoteWrapper = "v_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "v_gR d_gR";
export var quoteExceptionSmall = "v_r0 z_r0";
export var quoteExceptionNormal = "v_r1 z_r1";
export var quoteExceptionLarge = "v_r2 z_r2";
export var quoteAuthorExceptionSmall = "v_r3 z_r3";
export var quoteAuthorExceptionNormal = "v_r4 z_r4";
export var quoteAuthorExceptionLarge = "v_r5 z_r5";